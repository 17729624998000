import axios from 'axios';

export default {
    created() {
        axios.defaults.headers.common['RequestVerificationToken'] = this.GetCookie('XSRF-Token');
    },
    methods: {
        GetCookie: function (name) {
            let value = "; " + document.cookie;
            let parts = value.split("; " + name + "=");
            if (parts.length == 2) return parts.pop().split(";").shift();
        },
    }
};