import axios from 'axios'

export default {
    namespaced: true,
    actions: {
        FetchAuthToken({ }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.BASE_URL}api/auth/token`)
                    .then(response => {
                        if (response.data.success) {
                            sessionStorage.appToken = response.data.token;
                            resolve(response.data);
                        } else reject(response.data.message);
                    }).catch(error => { reject(`FetchAuthToken: ${error.message}`); });
            });
        },
    },
};
