<template>
    <v-app>
        <div v-if="!hasLoaded">
            <v-container grid-list-md>
                <v-row>
                    <v-col xs="4" offset-xs="4" class="text-center">
                        <v-progress-circular indeterminate color="primary" title="Loading" size="80" width="4">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAALEgAACxIAQiEt8QAAAUmSURBVHhe7ZptbBRFHMbnaBVoUShEaIMBBSpyGsVIwltIqBoLNgHCSwBJNFrCN/WDfpAYRfxCCAQ+8AmSBhNjQkxMMVQD2A8VKIHwEgoJVA2NvIUoXqEUbKHF83luZuvd3sze7l232cL8kifzn23vsv9n/zM7s3vCYrFYLBaLxWKxPJLEVOtJ7dyNH6B5RfZy8gBqhy5Cx6Bzdc0bkmgDEfuxZROaCtkz0gOtS9a8HPj7HfwaUI9miewF5ndoK1QHI2hOTpB8MZpOaFjqgDcTYMAVFQdmiGrDpBLaCTXCyLLUkdxMgfwkT15UbV4MhAEO86F9MOEx2fUkSFKDxgAyF/pIhp4MKgM4rtOVi499VMGgMeAOJrbidOHYKOh9iJOYjnJongyNBElqGibNIhUHpt+HAEzogHYj/EQe0TJbtVkgGU5+k2Uvg5uqdTMcmiTD4IQ5B3wHme7PXif8PMRKcrNPtTryHgahGYAquIXmjuxl4XU7NCVzCPpbhllEzwDFv6p14zVmTcmchy7IMIvIGpAPpmRaoUfWgOtY7nISNBlQiclzqIoDESkDkMSTaCbIXgZO4iYDuK6YKsNgRK0CXoB0GzQncc4DJvIaBlEzwJSEY8BVyLTAevgNUPt+ToY6om/ArTFPiFPz42Lv2qrXMN4TUA/UDh2BeC65KoCY5oHoGtBVOlQ0LZkh6te9LlrmPCfax44cgcOjIa74uCh6BleXawbOAW5u4m/XVUxMBkyEifzeQIRuQKJ8lNhbWyXa4k+LZPb0dgRaAU3ByT+FdhwPunCXvMkA5hKXoX9CNaCzrFQcWD1HdI3IerjDx2Rv4spyV0gTxkJ+yp+YDCCBh0GoBjQtmhHrHv646vXxM/QqdA1XvYEt9BX0IaTDnXAbdE+GWUTHACQ388b4Mi5s0uHDy6UQd4MnoBqI5/AuZHrommEAqqYXDStIR6Qq4C3VptOIBLhDTECfQsuh6dB9yIRu8WMaBpEyYKJq01mAyqiECVehHeifht6AuvlHDf9Al2SYgWlFWIHvH6NiX4RpgG7F1ghdxkm+DTF5jucvIZqg41cYpdtS99tEGKYBR1Xr8Be0FnoW4tg/AK2CeMK8BeowJTooDKgfdvdel4odSnBFW6E10Hr0m6C7kG4BRBaiUlrdwvHv5Z+1RMMAJNhd9cPJ+0W9fRXMe/3FWEPLT0MazuxHIr+hz1sgnw6ZTpqrRG5z3eKbIxORqQBR8ccNUb2nWZTe7iuE0djsLkzGYtWI+crsDIz6E22gk86BqZq0hGoAKCq/nBDLdjaKWQfPinFXEqKoN+PdyUFUAitgmuz2C2X4zvEqzkloBqgXoaWMi3seiPjJNlHzzWHxzpYGsWrH/m9xmC9QPoP4DoDP9vsT3xUVZgWshLK2P7FkUpR0dp9H6XdA3N+bTvYc9LmHtkEmfBtQ6O8DuKobKcM+eOW53N0OpSpAQ1Vd8wbeAbhk/gLNRsYuNsMgrha14HPcZPAOonuJ8jU++56KPSm0Arj/dr8c5cuLXZApee7tuQN0MF0tr3s97zJcPvNXKDoiMQRMbMHV54bGIS8DFKb/iaNCfOU20Ab8AnEPkAInyWf5vB268Xr2l47JgBLI1wvTgTSA+4DFrqvPRY1uDF9Did9WsRcFL4kHwgCeZC1UjeQ7Ukf+p5DyJwUb4PcuwE3MS7LnC+4EUz+TQ9LGlxkYAgvQ6J4bHEUF7FGxEXyeEy1/TqfjOL6D6w2LxWKxWCwWi8VicSHEf4IhcpiID+mTAAAAAElFTkSuQmCC" />
                        </v-progress-circular>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col xs="4" offset-xs="4" class="text-center">
                        <h3>{{ loadingMessage }}</h3>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div v-else>
            <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp"
                       :clipped-right="$vuetify.breakpoint.lgAndUp"
                       color="secondary"
                       dark
                       app
                       fixed>
                <v-toolbar-title>
                    <span class="text-h6" style="line-height:2.5em;">Reset Me</span>
                    <beta-chip></beta-chip>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-app-bar>
            <v-main>
                <v-container fluid>
                    <v-slide-y-transition mode="out-in">
                        <v-row wrap>
                            <v-col class="mx-2">
                                <router-view></router-view>
                            </v-col>
                        </v-row>
                    </v-slide-y-transition>
                </v-container>
            </v-main>
        </div>
    </v-app>
</template>

<script>
    import Vue from 'vue';
    import { eventBus } from '@/main'
    import is from 'is_js'

    import Toaster from 'v-toaster';
    import 'v-toaster/dist/v-toaster.css';
    Vue.use(Toaster, { timeout: 5000 });

    import globalMixin from '@/views/components/mixins/base/global.js'
    import xsrfMixin from '@/views/components/mixins/base/xsrf.js'
    import appApiMixin from '@/views/components/mixins/appApi.js'

    import BetaChip from '@/views/components/toolbar/BetaChip'

    export default {
        components: {
            'beta-chip': BetaChip,
        },
        mixins: [
            globalMixin,
            xsrfMixin,
            appApiMixin,
        ],
        data() {
            return {
                loadingMessage: 'Loading...',
                hasLoaded: false,
                showDrawer: true,
                appTokenIntervalID: null,
            }
        },
        mounted() {
            this.InitialiseErrorAndConsole();

            if (is.ie() || is.edge()) window.location.href = process.env.BASE_URL + 'Browser/Index';
            else {
                eventBus.$off('app-api-auth-token-fetch:started').$on('app-api-auth-token-fetch:started', () => this.loadingMessage = 'Connecting to services...');
                eventBus.$off('app-api-auth-token-fetch:success').$on('app-api-auth-token-fetch:success', () => this.hasLoaded = true);

                this.LoadAppAuthToken();
                this.appTokenIntervalID = setInterval(() => this.ReacquireAppToken(), 10000);
            }
        },
        methods: {
            InitialiseErrorAndConsole() {
                eventBus.$off('alert:success').$on('alert:success', response => this.$toaster.success(response.message));
                eventBus.$off('alert:error').$on('alert:error', error => {
                    try { this.$toaster.error(JSON.parse(error)?.Message); }
                    catch (ex) { this.$toaster.error(error); }
                });

                eventBus.$off('console:error').$on('console:error', error => {
                    try { console.error(JSON.parse(error)?.Message); }
                    catch (ex) { console.error(error); }
                });
            }
        },
    }
</script>