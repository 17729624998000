import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#23B8CE',
                secondary: '#643177',
                error: '#E74620',
                info: '#1768A2',
                success: '#5AB55E',
                warning: '#FB8C00',
            },
            dark: {
                primary: '#23B8CE',
                secondary: '#643177',
                error: '#E74620',
                info: '#1768A2',
                success: '#5AB55E',
                warning: '#FB8C00',
            },
        },
    },
});
