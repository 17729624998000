<template>
    <v-chip v-if="InDevelopment"
            label
            color="orange darken-2"
            text-color="white"
            style="margin-left:8px;">
        <v-icon left>fa fa-flask</v-icon>
        Test Version
    </v-chip>
</template>

<script>
    import globalMixin from '@/views/components/mixins/base/global.js'

    export default {
        mixins: [globalMixin],
    }
</script>