export default {
    data() {
        return {
            rules: {
                required: v => (!!v && v.length > 0) || 'Required',
                minimumLength: v => v.length >= 14 || '14 characters minimum',
                numberRequired: v => (!!v && parseFloat(v) > 0) || 'Valid Number Required',
                boolRequired: v => v != null || 'Required',
                objectRequired: v => !!v || 'Required',
            },
        }
    },
};