import axios from 'axios'

export default {
    namespaced: true,
    actions: {
        ChangePassword({ }, obj) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.BASE_URL}api/account/password/change`, obj, { headers: { Authorization: `Bearer ${sessionStorage.appToken}` } })
                    .then(response => {
                        if (response.data.success) resolve(response.data);
                        else reject(response.data.message);
                    }).catch(error => reject(`ChangePassword: ${error.message}`));
            });
        },
    },
};
