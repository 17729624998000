import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import store from '@/store';
import { sync } from 'vuex-router-sync';
import vuetify from '@/vuetify';

import '@/assets/app-custom.css';

export const eventBus = new Vue();

import App from '@/App.vue';

Vue.prototype.$http = axios;

sync(store, router);

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
