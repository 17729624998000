import Vue from 'vue'
import Vuex from 'vuex'
import appApiStore from './base/appApi'
import enterpriseDataApiStore from './base/enterpriseDataApi'
import accountStore from './account'

Vue.use(Vuex)

export default new Vuex.Store({
    
    modules: {
        appApi: appApiStore,
        enterpriseDataApi: enterpriseDataApiStore,
        account: accountStore,
    }
    
});
