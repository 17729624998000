export default {
    data() {
        return {
            applicationName: 'ResetMe.App',
            applicationTitle: 'Template App',
        }
    },
    computed: {
        AppUri() { return process.env.BASE_URL; },
        InDevelopment() { return window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('staging.azurewebsites.net') > -1; },
    },
};