<template>
    <v-dialog ref="dialog"
              v-model="modal"
              :return-value.sync="DateForTextBox"
              persistent
              width="290px"
              hide-details>
        <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="DateForTextBox"
                          :label="label"
                          readonly
                          v-bind="attrs"
                          v-on="on">
                <template v-slot:prepend-inner>
                    <v-icon class="mt-1 mr-2">fas fa-calendar</v-icon>
                </template>
            </v-text-field>
        </template>
        <v-date-picker v-model="DateForDatePicker" scrollable>
            <v-spacer></v-spacer>
            <v-btn @click="modal = false" text>Cancel</v-btn>
            <v-btn @click="$refs.dialog.save(DateForTextBox)" color="success" dark>OK</v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
    import moment from 'moment'

    export default {
        props: ['label', 'model'],
        data() {
            return {
                modal: false,
            }
        },
        computed: {
            dateProperty: {
                get() { return this.model; },
                set(value) { this.$emit('update:model', value); }
            },
            DateForTextBox: {
                get() { return moment(this.dateProperty, 'YYYY-MM-DD').locale('en-gb').format('D-MMM-YYYY'); },
                set(value) { this.dateProperty = moment(value, 'D-MMM-YYYY').locale('en-gb').format('YYYY-MM-DD'); }
            },
            DateForDatePicker: {
                get() { return moment(this.dateProperty, 'YYYY-MM-DD').locale('en-gb').format('YYYY-MM-DD'); },
                set(value) { this.dateProperty = moment(value, 'YYYY-MM-DD').locale('en-gb').format('YYYY-MM-DD'); }
            },
        },
    }
</script>
