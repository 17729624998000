import { mapActions } from 'vuex'
import { eventBus } from '@/main'

export default {
    methods: {
        ...mapActions({
            ChangePassword: 'account/ChangePassword',
        }),
        ResetPassword(message) {
            eventBus.$emit('password-change:started');
            this.ChangePassword(message)
                .then(response => eventBus.$emit('password-change:success', response))
                .catch(error => eventBus.$emit('password-change:error', error))
                .finally(() => eventBus.$emit('password-change:finished'));
        },
    }
};