import moment from 'moment'

export default {
    filters: {
        // Dates and Times
        formatDate(date) {
            if (typeof date === 'undefined' || date === null || date.length == 0) return '';
            return moment(date, 'YYYY-MM-DD').locale('en-gb').format('D-MMM-YYYY');
        },
        formatLongDate(date) {
            if (typeof date === 'undefined' || date === null || date.length == 0) return '';
            return moment(date, 'YYYY-MM-DD').locale('en-gb').format('dddd Do MMMM YYYY');
        },
        formatDateTime(dateTime) {
            if (typeof dateTime === 'undefined' || dateTime === null || dateTime.length == 0) return '';
            return moment(dateTime, 'YYYY-MM-DD HH:mm').locale('en-gb').format('D-MMM-YYYY HH:mm');
        },
        formatTime(time) {
            if (typeof time === 'undefined' || time === null || time.length == 0) return '';
            return moment(time, 'YYYY-MM-DD HH:mm').locale('en-gb').format('HH:mm');
        },
        calculateAge(dateOfBirth) {
            if (typeof dateOfBirth === 'undefined' || dateOfBirth === null || dateOfBirth.length == 0) return 0;
            return Math.floor((new Date() - new Date(dateOfBirth).getTime()) / 31556925994);
        },
        formatTimeSpan(value) {
            if (typeof value === 'undefined' || value === null) return '0h 0m';
            var hours = Math.floor(value / 60);
            var minutes = value % 60;
            return `${hours}h ${minutes}m`;
        },


        formatBoolean(bool) {
            if (typeof bool === 'undefined' || bool === null || bool.length == 0) return '';
            return bool ? 'Yes' : 'No';
        },

        formatAcademicSession(session) {
            if (typeof session === 'undefined' || session === null || session.length == 0) return '';
            return session.toString() + '/' + (session + 1).toString().substring(2, 4);
        },

        formatCurrency(value) {
            if (typeof value === 'undefined' || value === null || value == 0) return '0.00';
            return parseFloat(value).toFixed(2);
        },

        formatBytes(bytes) {
            if (typeof bytes === 'undefined' || bytes === null || bytes.length == 0 || bytes === 0) return '0 Bytes';
            let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },

        formatAddress(address) {
            if (typeof address === 'undefined' || address === null || address.length == 0) return '';
            return `<${address}>`;
        },

        // Text
        nl2br(str) {
            if (typeof str === 'undefined' || str === null || str.length == 0) return '';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
        },
        excerpt(str) {
            if (typeof str === 'undefined' || str === null || str.length == 0) return '';
            else if (str.length > 150) return `${str.substring(0, 150)}...`;
            else return str;
        },
    },
};