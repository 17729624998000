import { mapActions } from 'vuex'
import { eventBus } from '@/main'

export default {
    methods: {
        ...mapActions({
            FetchApiAuthToken: 'appApi/FetchAuthToken',
        }),
        LoadAppAuthToken() {
            eventBus.$emit('app-api-auth-token-fetch:started');
            this.FetchApiAuthToken()
                .then(authToken => eventBus.$emit('app-api-auth-token-fetch:success', authToken))
                .catch(error => eventBus.$emit('console:error', error))
                .finally(() => eventBus.$emit('app-api-auth-token-fetch:finished'));
        },
        ReacquireAppToken() {
            if (sessionStorage.appToken != null) {
                let tokens = sessionStorage.appToken.split('.');

                if (tokens.length > 1) {
                    let segment2 = JSON.parse(atob(tokens[1]));
                    let expiryDate = new Date(segment2.exp * 1000);

                    if (expiryDate != null) {
                        let difference = expiryDate - new Date();

                        if (difference <= 0) {
                            clearInterval(this.appTokenIntervalID);
                            this.LoadAppAuthToken();
                        }
                    }
                }
            }
        }
    }
};