import axios from 'axios'

export default {
    namespaced: true,
    actions: {
        FetchUserDefinedOption({ }, obj) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.BASE_URL}api/enterprisedata/data/fetch/users/userdefinedoption`, obj, { headers: { Authorization: `Bearer ${sessionStorage.appToken}` } })
                    .then(response => {
                        if (response.status === 200) resolve(CoalesceJSON(response.data.payload, {}));
                        else reject(response);
                    }).catch(error => reject(`FetchUserDefinedOption: ${error.message}`));
            });
        },
        UpdateUserDefinedOption({ }, obj) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.BASE_URL}api/enterprisedata/process/update/users/userdefinedoption`, obj, { headers: { Authorization: `Bearer ${sessionStorage.appToken}` } })
                    .then(response => {
                        if (response.status === 200) resolve(response.data);
                        else reject(response);
                    }).catch(error => reject(`UpdateUserDefinedOption: ${error.message}`));
            });
        },
        FetchToolbarLinks({ }, obj) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.BASE_URL}api/enterprisedata/data/fetch/toolbarlinks`, obj, { headers: { Authorization: `Bearer ${sessionStorage.appToken}` } })
                    .then(response => {
                        if (response.data.success) resolve(CoalesceJSON(response.data.payload));
                        else reject(response.data.message);
                    }).catch(error => reject(`FetchToolbarLinks: ${error.message}`));
            });
        },
        FetchConstants({ }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.BASE_URL}api/enterprisedata/data/fetch/constants`, { headers: { Authorization: `Bearer ${sessionStorage.appToken}` } })
                    .then(response => {
                        if (response.status === 200) resolve(CoalesceJSON(response.data.payload));
                        else reject(response);
                    }).catch(error => reject(`FetchConstants: ${error.message}`));
            });
        },
    },
}
function CoalesceJSON(object, returnObject) {
    if (object == null || object == '') return returnObject;
    else if (typeof object == 'object') return object;
    else return JSON.parse(object);
};
