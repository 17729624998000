<template>
    <div v-cloak>
        <v-dialog v-model="dialogInformation"
                  min-width="640px"
                  max-width="800px"
                  persistent>
            <v-card>
                <v-toolbar color="info" flat dark dense>
                    <v-toolbar-title><v-icon>fas fa-info-circle</v-icon> Important Information</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-alert type="info" dense outlined text class="my-4">This facility is only available to learners with active enrolments in the current session, or active staff members</v-alert>
                </v-card-text>
                <v-card-text>
                    <p>When changing passwords, staff and learners are encouraged to consider the following guidance:</p>
                    <ul>
                        <li>Use a minimum password length of 14 characters</li>
                        <li>Include lowercase and uppercase alphabetic characters, numbers and symbols</li>
                        <li>Avoid using the same password twice (i.e. don't use the same password to login to College systems as you do for accessing your Amazon shopping account)</li>
                        <li>Avoid repeated characters, dictionary words, usernames, relative or pet names, romantic links (current or past!) and biographical information (e.g., ID numbers, ancestors' names or dates)</li>
                        <li>Avoid using information that is or might become widely known - for example birth dates of yourself, family members or your children</li>
                    </ul>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="CloseInformationDialog" text>OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row>
            <v-col cols="12" lg="6" offset-lg="3" md="8" offset-md="2">
                <v-card elevation="4">
                    <v-toolbar color="primary" flat dark dense>
                        <v-toolbar-title>Reset Password</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid">
                            <v-container grid-list-lg fluid>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field ref="username"
                                                      label="Username"
                                                      hint="e.g. 1800401 for learners, or m.jones for staff"
                                                      v-model="username"
                                                      autofocus
                                                      :rules="[rules.required]"
                                                      required></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <date-picker label="Date of Birth" :model.sync="dateOfBirth"></date-picker>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field class="uppercase"
                                                      label="Post Code"
                                                      v-model="postCode"
                                                      :rules="[rules.required]"
                                                      hint="Include the space, i.e. DD11 1AA"
                                                      persistent-hint
                                                      required></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <v-text-field v-model="password"
                                                      :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                                                      :rules="[rules.required, rules.minimumLength]"
                                                      :type="showPassword ? 'text' : 'password'"
                                                      label="New Password"
                                                      counter
                                                      @click:append="showPassword = !showPassword"
                                                      required></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <vue-recaptcha ref="recaptcha"
                                                       sitekey="6Lez3MQUAAAAAI33mlwIIkM4L8ZpAb-R3pvhlqXO"
                                                       :loadRecaptchaScript="true"
                                                       @verify="VerifyRecaptcha"></vue-recaptcha>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="Submit"
                               :loading="submitted"
                               :disabled="!verified || submitted"
                               :color="!verified || submitted ? '' : 'green'"
                               :dark="verified && !submitted">Submit</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogComplexity"
                  min-width="640px"
                  max-width="800px"
                  persistent>
            <v-card>
                <v-toolbar color="info" flat dark dense>
                    <v-toolbar-title><v-icon>fas fa-exclamation-triangle</v-icon> Password Complexity</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-alert type="warning" dense outlined text class="my-4">The password you supplied does not meet our password complexity rules</v-alert>
                </v-card-text>
                <v-card-text>
                    <p>When changing passwords, staff and learners are encouraged to consider the following guidance:</p>
                    <ul>
                        <li>Use a minimum password length of 12 characters</li>
                        <li>Include lowercase and uppercase alphabetic characters, numbers and symbols</li>
                        <li>Avoid using the same password twice (i.e. don't use the same password to login to College systems as you do for accessing your Amazon shopping account)</li>
                        <li>Avoid repeated characters, dictionary words, usernames, relative or pet names, romantic links (current or past!) and biographical information (e.g., ID numbers, ancestors' names or dates)</li>
                        <li>Avoid using information that is or might become widely known - for example birth dates of yourself, family members or your children</li>
                    </ul>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialogComplexity = false" text>OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { eventBus } from '@/main'
    import VueRecaptcha from 'vue-recaptcha'

    import filtersMixin from '@/views/components/mixins/base/filters.js'
    import rulesMixin from '@/views/components/mixins/base/rules.js'
    import appApiMixin from '@/views/components/mixins/appApi.js'
    import accountMixin from '@/views/components/mixins/account.js'

    import datePicker from '@/views/components/controls/DatePicker'

    export default {
        mixins: [
            filtersMixin,
            rulesMixin,
            appApiMixin,
            accountMixin,
        ],
        components: {
            VueRecaptcha,
            'date-picker': datePicker,
        },
        data() {
            return {
                dialogInformation: true,

                username: '',
                dateOfBirth: new Date(),
                postCode: '',
                password: '',
                showPassword: false,
                challenge: '',
                valid: true,
                verified: false,
                submitted: false,

                dialogComplexity: false,
            }
        },
        mounted() {
            eventBus.$off('password-change:started').$on('password-change:started', () => this.submitted = true);
            eventBus.$off('password-change:success').$on('password-change:success', response => {
                if (response.success) {
                    this.$toaster.success(response.message);

                    this.Clear();
                    this.$refs.form.resetValidation();
                    this.$refs.username.focus();
                }
                else this.$toaster.error(response.message);
            });
            eventBus.$off('password-change:error').$on('password-change:error', error => {
                if (error.indexOf('The password does not meet the password policy requirements') > -1) {
                    this.dialogComplexity = true;
                }
                else this.$toaster.error(error);

                this.$refs.recaptcha.reset();
                this.verified = false;
            });
            eventBus.$off('password-change:finished').$on('password-change:finished', () => this.submitted = false);
        },
        methods: {
            CloseInformationDialog() {
                this.dialogInformation = false;
                this.$refs.username.focus();
            },
            VerifyRecaptcha(response) {
                this.challenge = response;
                this.verified = true;
            },
            Submit() {
                if (this.$refs.form.validate()) {
                    this.ResetPassword({
                        Username: this.username,
                        DateOfBirth: this.dateOfBirth,
                        PostCode: this.postCode,
                        Password: this.password,
                        Challenge: this.challenge
                    });
                }
            },
            Clear() {
                this.username = '';
                this.dateOfBirth = new Date();
                this.postCode = '';
                this.password = '';

                this.challenge = '';
                this.$refs.recaptcha.reset();
                this.verified = false;

                this.submitted = false;
            }
        }
    }
</script>

<style>
    .v-card li {
        line-height: inherit !important;
    }
</style>